import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 创建节目内容
 */
export function createVideoFile (data) {
  return request({
    url: '/ooh-inventory/v1/video/createVideoFile',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 删除节目内容
 */
export function deleteVideoFile (data) {
  return request({
    url: '/ooh-inventory/v1/video/deleteVideoFile',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询节目内容
 */
export function listVideoFiles (data) {
  return request({
    url: '/ooh-inventory/v1/video/listVideoFiles',
    method: 'post',
    data: qs.stringify(data)
  })
}
