<template>
  <div>
    <h4 class="workplatform-title m-t-10 m-b-10">视频内容清单</h4>
    <!-- <Row class="p-b-10">
            <i-col span="24">
                <label>类型：</label>
                <div @click="handelChangeScreenType(0)" :class="screenType===0?'workplatform-radio-button-active':'workplatform-radio-button'">分组投放</div>
                <div @click="handelChangeScreenType(1)" :class="screenType===1?'workplatform-radio-button-active':'workplatform-radio-button'">全部视频</div>
            </i-col>
        </Row> -->
    <!-- <Row class="p-b-10">
            <i-col span="24">
                <label>分组：</label>
                <div v-for="(item,index) in groups"  :key="index" @click="handleChangeGroup(item)" class="m-b-2"
                    :class="chooseGroup === item.id?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.name}}</div>
            </i-col>
        </Row> -->
    <!-- <Row class="p-b-5">
          <i-col span="24">
            <label>操作：</label>
            <Button size="small" type="info" icon="md-add">添加视频</Button>
            <Button size="small" class="m-l-5" type="info" icon="md-hammer">投放设置</Button>
          </i-col>
        </Row> -->

    <Table
      stripe
      size="small"
      :data="videolist"
      :columns="dataColumns"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="materialTotal"
        :page-size="materialQuery.pageSize"
        show-total
        :current="materialQuery.pageNum"
        @on-change="handleChangePage"
      ></Page>
    </div>

    <h4 class="workplatform-title m-t-10 m-b-10">新增视频</h4>
    <Row class="m-b-5">
      <!-- <i-col span="5">名称：</i-col> -->
      <i-col span="24">
        <Input
          size="small"
          placeholder="上传视频名称..."
          style="width: 100%"
          v-model="contentModel.fileName"
        />
      </i-col>
    </Row>
    <!-- <Row class="p-b-5">
      <i-col span="5">播出时长：</i-col>
      <i-col span="19">
        <InputNumber
          size="small"
          :min="1"
          v-model="contentModel.duration"
          :formatter="value => `${value}秒`"
          :parser="value => value.replace('秒', '')"
          placeholder="上传视频时长..."
          style="width: 100%"
        ></InputNumber>
      </i-col>
    </Row> -->
    <Row class="m-b-5">
      <i-col span="24">
        <Upload
          type="drag"
          :before-upload="handleUpload"
          accept="video/*"
          action
        >
          <div style="padding: 5px 0">
            <Icon
              type="ios-cloud-upload"
              size="52"
              style="color: #3399ff"
            ></Icon>
            <p>添加视频文件并上传</p>
          </div>
        </Upload>
      </i-col>
    </Row>
    <Row
      class="m-b-10"
      v-if="contentModel.file"
    >
      <i-col
        span="15"
        offset="5"
      >{{contentModel.file.name.length>20?contentModel.file.name.substring(0,19)+'...':contentModel.file.name}}</i-col>
      <!-- <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col> -->
      <i-col
        span="4"
        class="text-center"
      >
        <a
          class="delete"
          @click="handleRemove()"
        >删除</a>
      </i-col>
      <i-col
        span="19"
        offset="5"
      >
        <Divider
          dashed
          style="margin:5px 0"
        />
      </i-col>
    </Row>
    <Row>
      <i-col
        span="19"
        offset="5"
        class="text-right"
      >
        <Button
          type="success"
          size="small"
          :loading="submitLoading"
          :disabled="btnDisabled"
          @click="handleSubmit"
        >保存并提交</Button>
      </i-col>
    </Row>
    <video-show
      ref="videoShow"
      domFlag="contentManage"
    ></video-show>
  </div>
</template>

<script>
import axios from 'axios'
import { eleArrangeMixins } from '../../mixins'
import { getStorage } from '@/utils/storage'
import { deleteVideoFile } from '@/api/inventory/video'

import VideoShow from '@/components/eleScreen/components/VideoShow.vue'
export default {
  mixins: [eleArrangeMixins],
  components: {
    VideoShow
  },
  data () {
    return ({
      dataColumns: [
        { title: '视频名称', key: 'fileName' },
        { title: '时长(秒)', key: 'duration', width: 80, align: 'center' },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('Icon', {
                props: { type: 'md-skip-forward', size: 18, color: '#19be6b' },
                style: { 'margin-left': '8px', cursor: 'pointer' },
                on: {
                  click: () => {
                    this.$refs.videoShow.show(params.row)
                  }
                }
              }),
              h('Icon', {
                props: { type: 'ios-trash-outline', size: 20, color: '#ef4f4f' },
                style: { 'margin-left': '8px', cursor: 'pointer' },
                on: {
                  click: () => {
                    this.handleDelVideo(params.row.id)
                  }
                }
              })
            ]
            )
          }
        }
      ],
      contentModel: {
        file: null,
        fileName: ''
      },
      submitLoading: false
    })
  },
  computed: {
    btnDisabled () {
      if (this.contentModel.file && this.contentModel.fileName) {
        return false
      } else {
        return true
      }
    }
  },
  mounted () {
    this.getContentMaterialData()
  },
  methods: {
    handleDelVideo (fileId) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该条数据？',
        onOk: () => {
          deleteVideoFile({ fileId }).then(res => {
            if (res && res.errcode === 0) {
              this.getContentMaterialData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleUpload (file) {
      this.contentModel.file = file
      return false
    },
    handleRemove () {
      this.contentModel.file = null
    },
    handleSubmit () {
      if (!this.contentModel.file) {
        this.$Notice.warning({ desc: '请添加视频文件' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认数据无误并提交？',
        onOk: () => {
          this.submitLoading = true
          const uploadData = new FormData()
          uploadData.append('duration', this.contentModel.duration)
          uploadData.append('fileName', this.contentModel.fileName)
          uploadData.append('publisherId', this.query.publisherId)
          uploadData.append('file', this.contentModel.file)
          try {
            axios.post(
              process.env.VUE_APP_API_URL_V2 +
              '/ooh-inventory/v1/video/createVideoFile',
              uploadData,
              {
                timeout: 15000, // 请求超时
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'ooh-auth': getStorage('ooh-token')
                },
                withCredentials: true
                // onUploadProgress (progress) {
                //   // 处理上传文件进度条数据
                //   that.percentage = Math.round(
                //     (progress.loaded / progress.total) * 100
                //   )
                // }
              }
            ).then((res) => {
              this.submitLoading = false
              if (res.data && res.data.errcode === 0) {
                this.getContentMaterialData()
                this.$Notice.success({ desc: '操作成功' })
                this.contentModel.fileName = ''
                this.contentModel.file = null
              } else {
                this.$Notice.error({ desc: res.data.errmsg })
              }
            })
          } catch (error) {
            this.submitLoading = false
          }
        }
      })
    }
  }
}
</script>
