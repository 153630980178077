import { listVideoFiles } from '@/api/inventory/video'

export const eleArrangeMixins = {
  data () {
    return {
      materialQuery: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        queryString: '',
        pageNum: 1,
        pageSize: 15
      },
      materialTotal: 0,
      videolist: []
    }
  },
  methods: {
    /**
     * 获取填充节目素材数据
     * @returns
     */
    getContentMaterialData () {
      listVideoFiles(this.materialQuery).then(res => {
        if (res && !res.errcode) {
          this.videolist = res.list
          this.materialTotal = res.totalRow
          this.materialQuery.pageNum = res.pageNumber
        } else {
          this.videolist = []
          this.materialTotal = 0
          this.materialQuery.pageNum = 1
        }
      })
    },
    handleChangePage (curPage) {
      this.materialQuery.pageNum = curPage
      this.getContentMaterialData()
    },
    /**
  * 查看详情
  */
    handleViewDetial (params) {
      this.$store.commit('set_cur_task_info', params)
      this.handleDetailPage()
      // 设置头部路由标签
      // this.setLinkTagArray({ key: 'installList', value: '上刊设置列表', actived: true })
      this.setLinkRouterMaps(['electronicPlayList', () => { this.handleDetailPage() }])
    },
    /**
   * 处理详情页面显示
   */
    handleDetailPage () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('Summary')
      this.setShowRightBlock(false)
      this.setRightComponent('')
      this.setBottomComponent('PlayList')
    }
  }
}
