<template>
  <div>
    <Modal
      v-model="showVideoMdal"
      width="800"
      @on-visible-change="videoModalChange"
      :footer-hide="true"
    >
      <p
        slot="header"
        style="text-align:center"
      >
        {{videoTitle}}
      </p>
      <div>
        <video
          :id="domFlag"
          :src="videoSrc"
          height="400"
          width="100%"
          controls="controls"
        >
          该浏览器不支持视频播放
        </video>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'VideoShow',
  props: {
    domFlag: [String]
  },
  data () {
    return {
      showVideoMdal: false,
      videoTitle: '',
      videoSrc: ''
    }
  },
  methods: {
    show (data) { // 父窗体调用改事件
      this.videoTitle = data.fileName || ''
      this.videoSrc = data.filePath
      this.showVideoMdal = true
    },
    videoModalChange (visible) {
      // 关闭modal暂停视频播放
      if (!visible) {
        window.document.getElementById(this.domFlag).pause()
      }
    }
  }
}
</script>
